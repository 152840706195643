import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {SuspenseView} from '../../components/misc/SuspenseView'
import {Sections} from '../../helpers/sections';
import {ServicesIndex} from "../../sections/services/services/ServicesIndex";
import {ServicesCreate} from "../../sections/services/services/ServicesCreate";
import {ServicesEdit} from "../../sections/services/services/ServicesEdit";
import {ClientsIndex} from "../../sections/clients/clients/ClientsIndex";
import {ClientsCreate} from "../../sections/clients/clients/ClientsCreate";
import {ClientsEdit} from "../../sections/clients/clients/ClientsEdit";
import {ServicesFormIndex} from "../../sections/clients/clientServices/forms/ServiceFormIndex";
import {ServiceFormEdit} from "../../sections/clients/clientServices/forms/ServiceFormEdit";

const clientsFormBreadcrumbs: Array<PageLink> = [
    {
        title: Sections.FORMS,
        path: '/client/services',
        isSeparator: false,
        isActive: false,
    },

    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ClientsFormRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                path='/forms'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={clientsFormBreadcrumbs} showPageTitle={false}>{'Index'}</PageTitle>
                        <ServicesFormIndex/>
                    </SuspenseView>
                }
            />

            <Route
                path='/forms/:id'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={clientsFormBreadcrumbs} showPageTitle={false}>{'Edit'}</PageTitle>
                        <ServiceFormEdit/>
                    </SuspenseView>
                }
            />
        </Routes>
    )
}

export default ClientsFormRoutes;
