import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {PageDescription, PageLink, PageTitle} from '../../../_metronic/layout/core'
import {SuspenseView} from '../../components/misc/SuspenseView'
import {Sections} from '../../helpers/sections';
import {UsersIndex} from "../../sections/user/user/UsersIndex";
import {UsersCreate} from "../../sections/user/user/UsersCreate";
import {UsersEdit} from "../../sections/user/user/UsersEdit";

const usersBreadCrumbs: Array<PageLink> = [
    {
        title: Sections.USERS,
        path: '/users/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const UserRoutes: React.FC = () => {
    return (
        <Routes>
            <Route index element={
                <SuspenseView>
                    <PageTitle breadcrumbs={[]} >{'Users'}</PageTitle>
                    <UsersIndex/>
                </SuspenseView>
            }/>

            <Route
                path='/create'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={usersBreadCrumbs} showPageTitle={false}>{'Create'}</PageTitle>
                        <UsersCreate/>
                    </SuspenseView>
                }
            />



            <Route
                path='/:id/edit'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={usersBreadCrumbs} showPageTitle={false}>{'Edit'}</PageTitle>
                        <UsersEdit/>
                    </SuspenseView>
                }
            />
        </Routes>
    )
}

export default UserRoutes;
