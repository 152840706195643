import {Column} from 'react-table'
import {TextCell} from "../../../modules/table/columns/TextCell";
import {CustomHeader} from "../../../modules/table/columns/CustomHeader";
import {ActionsCell} from "../../../modules/table/columns/ActionsCell";
import {QUERIES} from "../../../../_metronic/helpers";
import {Service} from "../../../models/services/Service";
import {Restricted, useAccessControl} from "../../../modules/auth/AuthAccessControl";

const servicesColumns: ReadonlyArray<Column<Service>> = [
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Name' className='min-w-125px'/>,
    id: 'name',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].name}/>,
  },
  {
    Header: (props) => (
        <Restricted to={'manage-services'}>
          <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px'/>
        </Restricted>
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const accessControl = useAccessControl();

      return (<ActionsCell
          id={props.data[props.row.index].id}
          path={'services'}
          queryKey={QUERIES.SERVICE_LIST}
          showView={false}
          showEdit={accessControl.userCan('manage-services')}
          showDelete={accessControl.userCan('manage-services')}
          title="Delete Service"
          text={`Are you sure you want to delete the service '${props.data[props.row.index].name}'?`}
      />)
    },
  }
]

export {servicesColumns}
