import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import axios from "axios";
import {useAccessApp} from "../../../../modules/general/AcessApp";
import {Role} from "../../../../models/user/Role";
import {getServiceById, updateService} from "../../../services/services/core/_requests";
import {extractErrors, GenericErrorMessage} from "../../../../helpers/form";
import {AlertMessageGenerator} from "../../../../helpers/AlertMessageGenerator";
import {AccessToastType, Actions, PageTypes} from "../../../../helpers/variables";
import {generatePageTitle} from "../../../../helpers/pageTitleGenerator";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import FormErrors from "../../../../components/forms/FormErrors";
import {Sections} from "../../../../helpers/sections";
import {getServiceFormFields, updateServiceForm} from "./core/_requests";
import {Service} from "../../../../models/services/Service";
import {Form} from "../../../../models/general/Form";
import {useAuth} from "../../../../modules/auth";
const editServiceFormSchema = Yup.object().shape({


})
const ServiceFormEdit = () => {
    const params = useParams();
    const [fields, setFields] = useState<any>([])
    const [values, setValues] = useState<any>([])
    const [service, setService] = useState<Service | undefined>()
    const [form, setForm] = useState<Form | undefined>()
    const {currentUser, hasRoles} = useAuth();
    const AccessApp = useAccessApp();
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState<string[]>([]);

    const initialValues = {
        name: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "name"))?.value || '',
        email: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "email"))?.value || '',
        phone_number:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "phone_number"))?.value || '',
        business_address :  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "business_address"))?.value || '',
        operating_hours:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "operating_hours"))?.value || '',
        business_website:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "business_website"))?.value || '',
        marketing_landing_sales_page:   (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "marketing_landing_sales_page"))?.value || '',
        facebook_page: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "facebook_page"))?.value || '',
        discord_group :  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "discord_group"))?.value || '',
        instagram_page:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "instagram_page"))?.value || '',
        conversation_medium:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "conversation_medium"))?.value || '',
        working_expectation:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "working_expectation"))?.value || '',
        like_dislike:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "like_dislike"))?.value || '',
        restriction_condition:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "restriction_condition"))?.value || '',
        weekly_status_update: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "weekly_status_update"))?.value || '',
        additional_comments: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "additional_comments"))?.value || '',
        business_function: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "business_function"))?.value || '',
        best_selling_products: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "best_selling_products"))?.value || '',
        problem_solve:  (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "problem_solve"))?.value || '',
        product_usp: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "product_usp"))?.value || '',
        common_outcome: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "common_outcome"))?.value || '',
        competitors_distinguished: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "competitors_distinguished"))?.value || '',
        selling_method: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "selling_method"))?.value || '',
        annual_revenues: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "annual_revenues"))?.value || '',
        monthly_revenue: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "monthly_revenue"))?.value || '',
        avg_annual_profit: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "avg_annual_profit"))?.value || '',
        avg_customer_lifeline: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "avg_customer_lifeline"))?.value || '',
        avg_single_order: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "avg_single_order"))?.value || '',
        total_offers: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "total_offers"))?.value || '',
        best_selling_offers: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "best_selling_offers"))?.value || '',
        short_term_goals: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "short_term_goals"))?.value || '',
        long_term_goals: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "long_term_goals"))?.value || '',
        website_platform: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "website_platform"))?.value || '',
        host_server: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "host_server"))?.value || '',
        prohibited: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "prohibited"))?.value || '',
        update_frequency: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "update_frequency"))?.value || '',
        website_updated_by: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "website_updated_by"))?.value || '',
        future_requirements: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "future_requirements"))?.value || '',
        developers: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "developers"))?.value || '',
        fb_ads_manager: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "fb_ads_manager"))?.value || '',
        fb_business_manager: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "fb_business_manager"))?.value || '',
        google_ads_manager: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "google_ads_manager"))?.value || '',
        optimize_google_merchant: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "optimize_google_merchant"))?.value || '',
        gtm: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "gtm"))?.value || '',
        ga4:(values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "ga4"))?.value || '',
        fb_page: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "fb_page"))?.value || '',
        ig_page: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "ig_page"))?.value || '',
        do_donts: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "do_donts"))?.value || '',
        domain_name:(values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "domain_name"))?.value || '',
        web_hosting: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "web_hosting"))?.value || '',
        ssl: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "ssl"))?.value || '',
        web_security_firewall: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "web_security_firewall"))?.value || '',
        email_service_account: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "email_service_account"))?.value || '',
        written_images_content: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "written_images_content"))?.value || '',
        favicon: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "favicon"))?.value || '',
        online_contents:(values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "online_contents"))?.value || '',
        video_audio_players: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "video_audio_players"))?.value || '',
        product_service_list: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "product_service_list"))?.value || '',
        target_audience: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "target_audience"))?.value || '',
        audience_persona: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "audience_persona"))?.value || '',
        preferred_languages: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "preferred_languages"))?.value || '',
        tone_of_voice: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "tone_of_voice"))?.value || '',
        logo: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "logo"))?.value || '',
        brand_colors: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "brand_colors"))?.value || '',
        brand_guidelines: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "brand_guidelines"))?.value || '',
        fonts: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "fonts"))?.value || '',
        paid_promotions: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "paid_promotions"))?.value || '',
        creative_bank: (values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "creative_bank"))?.value || '',
        sorted_email_list:(values && values.length > 0 && values.find((entry: any) => entry.field && entry.field.name === "sorted_email_list"))?.value || '',
    }

    const cancel = () => {
        navigate('/client/services/' + params.service +'/forms')
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: editServiceFormSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true)

            updateServiceForm(params.service, params.id, values).then(response => {
                    if (axios.isAxiosError(response)) {
                        // we need to show the errors
                        setFormErrors(extractErrors(response));

                    } else if (response === undefined) {
                        // show generic error message
                        setFormErrors([GenericErrorMessage])
                    } else {
                        // we were able to store the user
                        AccessApp.setAlert({message: new AlertMessageGenerator('form', Actions.EDIT, AccessToastType.SUCCESS).message, type: AccessToastType.SUCCESS})
                        navigate(`/client/services/${params.service}/forms`);
                    }
                }
            );
        }
    })
    useEffect(() => {
        getServiceFormFields(params.service, params.id).then(response => {
            setFields(response.fields);
            setService(response.service);
            setForm(response.form);
            setValues(response.values)
            AccessApp.setPageTitle(generatePageTitle(Sections.FORMS, PageTypes.EDIT, response?.service?.name +' - ' + response?.form?.name));

        });
    }, [params.id]);



    return (
        <>
            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <span className="card-icon">
                            <i className="las la-plus fs-2"/>
                        </span>
                        <h3 className="card-label">
                            Edit [ {service?.name} - {form?.name} ]
                        </h3>
                    </div>
                </div>
                <KTCardBody className='py-4'>
                    <FormErrors errorMessages={formErrors}/>

                    <form className='form' onSubmit={formik.handleSubmit} noValidate>
                        {/* begin::Scroll */}
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7 pt-5'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'
                        >
                            {/* begin::Input group */}
                            {values && values.length == 0 && fields && fields.length > 0 ? fields.map((field:any) => (
                                <div className='fv-row mb-7' key={field.id}>
                                    {/* begin::Label */}
                                    <label className='fw-bold fs-6 mb-2'>{field.placeholder}</label>
                                    {/* end::Label */}

                                    {/* begin::Input */}
                                    <input
                                        placeholder={field.placeholder}
                                        {...formik.getFieldProps(field.name)}
                                        type='text'
                                        name={field.name}
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',

                                        )}

                                        autoComplete='off'
                                        disabled={formik.isSubmitting}
                                    />

                                    {/* end::Input */}
                                </div>
                            )) : (values && values.length > 0 && values.map((value:any) => (
                                <div className='fv-row mb-7' key={value.id}>
                                    {/* begin::Label */}
                                    <label className='fw-bold fs-6 mb-2'>{value?.field.placeholder}</label>
                                    {/* end::Label */}

                                    {/* begin::Input */}
                                    <input

                                        placeholder={value?.field.placeholder }
                                        {...formik.getFieldProps(value?.field.name)}
                                        type='text'
                                        name={value?.field.name}
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',

                                        )}
                                        value={value?.value}
                                        autoComplete='off'
                                        disabled={formik.isSubmitting}
                                    />

                                    {/* end::Input */}
                                </div>
                            )))}

                            {/* end::Input group */}
                        </div>
                        {/* end::Scroll */}

                        {/* begin::Actions */}
                        <div className='py-5'>
                            <button
                                type='reset'
                                onClick={() => cancel()}
                                className='btn btn-light me-3'
                                data-kt-users-modal-action='cancel'
                                disabled={formik.isSubmitting}
                            >
                                Cancel
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Submit</span>
                                {(formik.isSubmitting) && (
                                    <span className='indicator-progress'>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}
                    </form>
                    {(formik.isSubmitting)}
                </KTCardBody>
            </KTCard>
        </>
    )
}

export {ServiceFormEdit}