import {Column} from 'react-table'
import {CustomHeader} from "../../../../modules/table/columns/CustomHeader";
import {TextCell} from "../../../../modules/table/columns/TextCell";
import {useAccessControl} from "../../../../modules/auth/AuthAccessControl";
import {ActionsCell} from "../../../../modules/table/columns/ActionsCell";
import {QUERIES} from "../../../../../_metronic/helpers";
import {Link, useParams} from "react-router-dom";

const serviceFormColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Name' className='min-w-125px'/>,
    id: 'name',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].name}/>,
  },
  {
    Header: (props) => (

        <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px'/>
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const accessControl = useAccessControl();
      const params = useParams();

      return (<ActionsCell
          id2={props.data[props.row.index].id}
          path={'client/services'}
          id={params.service}
          path2={'forms'}
          queryKey={QUERIES.SERVICE_FORM_LIST}
          showView2={true}
          showEdit={false}
          showDelete={false}
          title="Delete Service"
          text={`Are you sure you want to delete the service '${props.data[props.row.index].name}'?`}
      />)
    },
  }
]

export {serviceFormColumns}
