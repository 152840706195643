import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react'
import toast, {Toaster, ToastOptions} from 'react-hot-toast';
import PendingIcon from '../../components/icons/Pending';
import {AccessToastType} from '../../helpers/variables';
import {WithChildren} from "../../../_metronic/helpers/react18MigrationHelpers";

type Alert = {
    message: string;
    type: AccessToastType;
}

type AccessContextProps = {
    alert: Alert | undefined
    setAlert: Dispatch<SetStateAction<Alert | undefined>>,
    pageTitle: string,
    setPageTitle: Dispatch<SetStateAction<string>>,
}

const initAccessContextPropsState = {
    alert: undefined,
    setAlert: () => {
    },
    pageTitle: '',
    setPageTitle: () => {
    },
}

const AccessContext = createContext<AccessContextProps>(initAccessContextPropsState)

const useAccessApp = () => {
    return useContext(AccessContext)
}

const AccessApp: FC<WithChildren> = ({children}) => {
    const [alert, setAlert] = useState<Alert | undefined>(undefined)
    const [pageTitle, setPageTitle] = useState<string>('')

    const color = {
        'success': '#50cd89',
        'error': '#f1416c',
        'pending': '#d5441c',
        'warning': '#FFA800'
    }

    const type = {
        'success': alert?.type,
        'error': alert?.type,
        'pending': 'success',
        'warning': alert?.type
    }

    const icon = {
        'pending': <PendingIcon/>,
        'warning': '⚠️',
    }

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    useEffect(() => {
        if (alert !== undefined) {
            const options: ToastOptions = {
                id: `alert-${alert.type}`,
                duration: 4000,
                position: 'top-center',
                style: {
                    border: '1px solid ' + (color as any)[alert.type],
                    padding: '16px',
                    color: '#000000',
                },
                iconTheme: {
                    primary: (color as any)[alert.type],
                    secondary: '',
                },
                ...(alert.type in icon ? {icon: (icon as any)[alert.type]} : {}),
            };

            if (['success', 'error', 'loading', 'blank', 'custom'].includes(alert.type)) {
                (toast as any)[(type as any)[(alert.type)]](alert.message, options)
            } else {
                (toast as any)(alert.message, options)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert]);

    return (
        <AccessContext.Provider value={{alert, setAlert, pageTitle, setPageTitle}}>
            {children}
            <Toaster/>
        </AccessContext.Provider>
    )
}

export {AccessApp, useAccessApp}