import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getUserById, storeUser, updateUser, updateUserPost} from "./core/_requests";
import {PageTitle} from "../../../../_metronic/layout/core";
import {KTCard, KTCardBody} from "../../../../_metronic/helpers";
import clsx from "clsx";
import {User} from "../../../models/user/User";
import Select from 'react-select'
import {getAllRoles} from "../role/core/_requests";
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {AccessToastType, Actions, PageTypes} from "../../../helpers/variables";
import FormErrors from "../../../components/forms/FormErrors";
import axios from "axios";
import {extractErrors, GenericErrorMessage} from "../../../helpers/form";
import {AlertMessageGenerator} from "../../../helpers/AlertMessageGenerator";

const editUserSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    roles: Yup.array()
        .min(1, 'At least one role is required') // Adjust the minimum value as needed
        .required('Role are required'),
})


const UsersEdit = () => {
    const AccessApp = useAccessApp();
    const [user, setUser] = useState<User | undefined>()
    const [roles, setRoles] = useState<any>([])
    const navigate = useNavigate()
    const params = useParams()
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const initialValues = {
        name: user?.name || '',
        email: user?.email || '',
        roles: user?.roles?.map((role) => ({value: role.id, label: role.name,})) || [],
        // image: null as File | null,


    }

    const cancel = () => {
        navigate('/users')
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: editUserSchema,
        onSubmit: async (values, {setSubmitting}) => {
            const roleIds = values.roles.map(role => role.value);

            setSubmitting(true)

            updateUser(params.id, {
                ...values,
                roles: roleIds
            }).then(response => {
                if (axios.isAxiosError(response)) {
                    // we need to show the errors
                    setFormErrors(extractErrors(response));
                } else if (response === undefined) {
                    // show generic error message
                    setFormErrors([GenericErrorMessage])
                } else {
                    // we were able to store the user
                    AccessApp.setAlert({
                        message: new AlertMessageGenerator('user', Actions.EDIT, AccessToastType.SUCCESS).message,
                        type: AccessToastType.SUCCESS
                    })
                    navigate(`/users`);
                }
            })

        },
    })
    const handleChange = (selectedOptions: any) => {
        formik.setFieldValue('roles', selectedOptions);
    };

    useEffect(() => {
        getUserById(params.id).then(response => {
            setUser(response)
            AccessApp.setPageTitle(generatePageTitle(Sections.USERS, PageTypes.EDIT , response?.name))
        })

        getAllRoles().then((response) => {
            setRoles(response.data);
        });

    }, [params.id]);

    return (
        <>
            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <span className="card-icon">
                            <i className="las la-plus fs-2"/>
                        </span>
                        <h3 className="card-label">
                            Edit User
                        </h3>
                    </div>
                </div>
                <KTCardBody className='py-4'>
                    <FormErrors errorMessages={formErrors}/>
                    <form className='form' onSubmit={formik.handleSubmit} encType={'multipart/form-data'} noValidate>
                        {/* begin::Scroll */}
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7 pt-5'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'>


                            {/* begin::Input group */}
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Name</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {/*<Field name="name" type="text" className={'form-control form-control-solid mb-3 mb-lg-0' + (formik.touched.name && formik.errors.name ? ' is-invalid' : 'is-valid')} />*/}
                                <input placeholder='Name'{...formik.getFieldProps('name')} type='text' name='name'
                                       className={clsx('form-control form-control-solid mb-3 mb-lg-0', {'is-invalid': formik.touched.name && formik.errors.name}, {
                                           'is-valid': formik.touched.name && !formik.errors.name,
                                       })} autoComplete='off' disabled={formik.isSubmitting}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            {/* end::Input group */}

                            {/* begin::Input group */}
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Email</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {/*<Field name="name" type="text" className={'form-control form-control-solid mb-3 mb-lg-0' + (formik.touched.name && formik.errors.name ? ' is-invalid' : 'is-valid')} />*/}
                                <input placeholder='Name'{...formik.getFieldProps('email')} type='email' name='email'
                                       className={clsx('form-control form-control-solid mb-3 mb-lg-0', {'is-invalid': formik.touched.email && formik.errors.email}, {
                                           'is-valid': formik.touched.email && !formik.errors.email,
                                       })} autoComplete='off' disabled={formik.isSubmitting}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            {/* end::Input group */}


                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Roles</label>
                                {roles && roles.length > 0 &&
                                    <Select
                                        className='react-select-styled react-select-solid react-select-lg'
                                        closeMenuOnSelect={false}
                                        placeholder={'Select an option'}
                                        options={roles.map((e: {
                                            name: any;
                                            id: any
                                        }) => ({label: e.name, value: e.id}))}
                                        isSearchable={true}
                                        onChange={handleChange}
                                        value={formik.values.roles}
                                        isMulti
                                    />
                                }
                                {formik.touched.roles && formik.errors.roles && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.roles}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/*<div className='fv-row mb-7'>*/}
                            {/*    <label className=' fw-bold fs-6 mb-2'>Image</label>*/}
                            {/*    <div className="mb-3">*/}
                            {/*        {*/}
                            {/*            user?.image && <img src={user?.image} className="w-25"*/}
                            {/*                                alt={`${user?.name} profile`}/>*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*    <input  name="image" type="file"*/}
                            {/*        className={'form-control fs-base'}*/}
                            {/*        onChange={(event) => {formik.setFieldValue('image', event.currentTarget.files?.[0]);}}*/}
                            {/*    />*/}


                            {/*</div>*/}
                        </div>
                        {/* end::Scroll */}

                        {/* begin::Actions */}
                        <div className='py-5'>
                            <button
                                type='reset'
                                onClick={() => cancel()}
                                className='btn btn-light me-3'
                                data-kt-users-modal-action='cancel'
                                disabled={formik.isSubmitting}
                            >
                                Cancel
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Submit</span>
                                {(formik.isSubmitting) && (
                                    <span className='indicator-progress'>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}
                    </form>
                    {(formik.isSubmitting)}
                </KTCardBody>
            </KTCard>
        </>
    )
}

export {UsersEdit}