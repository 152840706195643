import axios, {AxiosError, AxiosResponse} from 'axios'
import {Response} from "../../../../../_metronic/helpers";
import {Role, RolesQueryResponse} from "../../../../models/user/Role";
import {Permission} from "../../../../models/user/Permission";
import {Client} from "../../../../models/clients/Client";

const API_URL = process.env.REACT_APP_API_URL
const GET_ROLES_URL = `${API_URL}/roles`

const getRoles = (query: string): Promise<RolesQueryResponse> => {
    return axios
        .get(`${GET_ROLES_URL}/all?${query}`)
        .then((d: AxiosResponse<RolesQueryResponse>) => d.data)
}


const getAllRoles = (): Promise<any> => {
    return axios
        .get(`${GET_ROLES_URL}` )
        .then((response) => response.data)
}
const getRoleById = (id: any): Promise<Role | undefined> => {
    return axios
        .get(`${GET_ROLES_URL}/${id}`)
        .then((response: AxiosResponse<Response<Role>>) => response.data)
        .then((response: Response<Role>) => response.data)
}

const createRole = async (role: Role): Promise<Role | AxiosError | undefined> => {
    return await axios
        .post(`${GET_ROLES_URL}`, role)
        .then(res => res.data.data).catch((error) => {
            return error;
        });
}

const updateRole = (id: any, values: any): Promise<Role | undefined> => {
    return axios
        .put(`${GET_ROLES_URL}/${id}`, values)
        .then((response: AxiosResponse<Response<Role>>) => response.data)
        .then((response: Response<Role>) => response.data)
}


export {getRoles, getRoleById, createRole, updateRole,getAllRoles}
