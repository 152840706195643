import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {SuspenseView} from '../../components/misc/SuspenseView'
import {Sections} from '../../helpers/sections';
import {ServicesIndex} from "../../sections/services/services/ServicesIndex";
import {ServicesCreate} from "../../sections/services/services/ServicesCreate";
import {ServicesEdit} from "../../sections/services/services/ServicesEdit";
import {ClientServicesIndex} from "../../sections/clients/clientServices/ClientServicesIndex";

const servicesBreadcrumbs: Array<PageLink> = [
    {
        title: Sections.SERVICES,
        path: '/clients',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ServicesRoutes: React.FC = () => {
    return (
        <Routes>
            <Route index element={
                <SuspenseView>
                    <PageTitle breadcrumbs={[]}>{'Services'}</PageTitle>
                    <ServicesIndex/>
                </SuspenseView>
            }/>

            <Route
                path='/create'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={servicesBreadcrumbs} showPageTitle={false}>{'Create'}</PageTitle>
                        <ServicesCreate/>
                    </SuspenseView>
                }
            />
            <Route
                path='/:id/edit'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={servicesBreadcrumbs} showPageTitle={false}>{'Edit'}</PageTitle>
                        <ServicesEdit/>
                    </SuspenseView>
                }
            />
        </Routes>
    )
}

export default ServicesRoutes;
