import axios, {AxiosError, AxiosResponse} from 'axios'
import {ID, Response} from "../../../../../../_metronic/helpers";
import {Service, ServiceQueryResponse} from "../../../../../models/services/Service";


const API_URL = process.env.REACT_APP_API_URL
const GET_SERVICES_FORMS_URL = `${API_URL}/client-service`

const getServiceForms = (query: string): Promise<ServiceQueryResponse> => {
    return axios
        .get(`${GET_SERVICES_FORMS_URL}/all?${query}`)
        .then((d: AxiosResponse<ServiceQueryResponse>) => d.data)
}

export const getServiceFormsIndex = (serviceId: number, query?: String): Promise<any> => {
    let url = `${GET_SERVICES_FORMS_URL}/${serviceId}/forms/all`;
    if (query) {
        url += `?${query}`;
    }

    return axios.get(url).then((response: AxiosResponse<any>) => response.data).catch((error) => {
        return error;
    });
}
const getAllServiceForms = (): Promise<any> => {
    return axios
        .get(`${GET_SERVICES_FORMS_URL}` )
        .then((response) => response.data)
}
const getServiceFormFields= (serviceId: any, formId: any): Promise<any | undefined> => {
    let url = `${GET_SERVICES_FORMS_URL}/${serviceId}/forms/${formId}`;

    return axios.get(url).then((response: AxiosResponse<any>) => response.data).catch((error) => {
        return error;
    });
}

const createServiceForm = async (service: Service): Promise<Service | AxiosError | undefined> => {
    return await axios
        .post(`${GET_SERVICES_FORMS_URL}`, service)
        .then(res => res.data.data).catch((error) => {
            return error;
        });
}

const updateServiceForm = async (serviceId: any, formId: any, values: any): Promise<Service | AxiosError | undefined> => {
    return await axios
        .put(`${GET_SERVICES_FORMS_URL}/${serviceId}/forms/${formId}`, values)
        .then(res => res.data.data).catch((error) => {
            return error;
        });
}



export {getAllServiceForms, getServiceFormFields, getServiceForms, updateServiceForm, createServiceForm}
