import React, {useEffect} from 'react'
import {getServiceForms, getServiceFormsIndex} from "./core/_requests";
import {useAccessApp} from "../../../../modules/general/AcessApp";
import {Sections} from "../../../../helpers/sections";
import {PageTypes} from "../../../../helpers/variables";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {TableHeader} from "../../../../modules/table/TableHeader";
import {KTCard, QUERIES} from "../../../../../_metronic/helpers";
import {generatePageTitle} from "../../../../helpers/pageTitleGenerator";
import {QueryRequestProvider} from "../../../../modules/table/QueryRequestProvider";
import {QueryResponseProvider} from "../../../../modules/table/QueryResponseProvider";
import {ListViewProvider} from "../../../../modules/table/ListViewProvider";
import {useAuth} from "../../../../modules/auth";
import {useParams} from "react-router-dom";
import {ServiceFormTable} from "./ServiceFormTable";


const ServiceFormList = () => {

    const AccessApp = useAccessApp();
    useEffect(() => {
        AccessApp.setPageTitle(generatePageTitle(Sections.FORMS, PageTypes.INDEX))
    }, []);

    const params = useParams();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{'Forms'}</PageTitle>
            <KTCard>
                <TableHeader name='Forms' url={`/client/services/${params.service}/forms`} permission={'manage-users'}/>
                <ServiceFormTable/>
            </KTCard>
        </>
    )
}

const ServicesFormIndex = () => {
    const {currentUser, hasRoles} = useAuth();
    let {service} = useParams();
    return (
        <QueryRequestProvider>
            <QueryResponseProvider id={QUERIES.SERVICE_FORM_LIST} requestFunction={getServiceFormsIndex} requestId={service} >
                <ListViewProvider>
                    <ServiceFormList/>
                </ListViewProvider>
            </QueryResponseProvider>
        </QueryRequestProvider>
    )

}

export {ServicesFormIndex}
