import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {SuspenseView} from '../../components/misc/SuspenseView'
import {Sections} from '../../helpers/sections';
import {ServicesIndex} from "../../sections/services/services/ServicesIndex";
import {ServicesCreate} from "../../sections/services/services/ServicesCreate";
import {ServicesEdit} from "../../sections/services/services/ServicesEdit";
import {ClientsIndex} from "../../sections/clients/clients/ClientsIndex";
import {ClientsCreate} from "../../sections/clients/clients/ClientsCreate";
import {ClientsEdit} from "../../sections/clients/clients/ClientsEdit";

const clientsBreadcrumbs: Array<PageLink> = [
    {
        title: Sections.CLIENTS,
        path: '/clients',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ClientsRoutes: React.FC = () => {
    return (
        <Routes>
            <Route index element={
                <SuspenseView>
                    <PageTitle breadcrumbs={[]}>{'Clients'}</PageTitle>
                    <ClientsIndex/>
                </SuspenseView>
            }/>

            <Route
                path='/create'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={clientsBreadcrumbs} showPageTitle={false}>{'Create'}</PageTitle>
                        <ClientsCreate/>
                    </SuspenseView>
                }
            />
            <Route
                path='/:id/edit'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={clientsBreadcrumbs} showPageTitle={false}>{'Edit'}</PageTitle>
                        <ClientsEdit/>
                    </SuspenseView>
                }
            />
        </Routes>
    )
}

export default ClientsRoutes;
