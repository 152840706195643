import {Column} from 'react-table'
import {TextCell} from "../../../modules/table/columns/TextCell";
import {CustomHeader} from "../../../modules/table/columns/CustomHeader";
import {ActionsCell} from "../../../modules/table/columns/ActionsCell";
import {QUERIES} from "../../../../_metronic/helpers";
import {Permission} from "../../../models/user/Permission";
import {Restricted, useAccessControl} from "../../../modules/auth/AuthAccessControl";

const permissionColumns: ReadonlyArray<Column<Permission>> = [
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Name' className='min-w-125px'/>,
    id: 'name',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].name}/>,
  },
  {
    Header: (props) => (
        <Restricted to={'manage-permissions'}>
          <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px'/>
        </Restricted>
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const accessControl = useAccessControl();

      return (<ActionsCell
          id={props.data[props.row.index].id}
          path={'permissions'}
          queryKey={QUERIES.PERMISSIONS_LIST}
          showView={false}
          showEdit={accessControl.userCan('manage-permissions')}
          showDelete={accessControl.userCan('manage-permissions')}
          title="Delete Permission"
          text={`Are you sure you want to delete the permission '${props.data[props.row.index].name}'?`}
      />)
    },
  }
]

export {permissionColumns}
