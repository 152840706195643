import React, {useEffect, useState} from "react";
import {Role} from "../../../models/user/Role";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createRole, getRoleById, updateRole} from "./core/_requests";
import {PageTitle} from "../../../../_metronic/layout/core";
import {KTCard, KTCardBody} from "../../../../_metronic/helpers";
import clsx from "clsx";
import {getAllPermissions, getPermissions} from "../permission/core/_requests";
import {Permission} from "../../../models/user/Permission";
import Select from "react-select";
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {AccessToastType, Actions, PageTypes} from "../../../helpers/variables";
import axios from "axios";
import {extractErrors, GenericErrorMessage} from "../../../helpers/form";
import {AlertMessageGenerator} from "../../../helpers/AlertMessageGenerator";
import FormErrors from "../../../components/forms/FormErrors";

const editRoleSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    permissions: Yup.array()
        .min(1, 'At least one permission is required') // Adjust the minimum value as needed
        .required('Permissions are required'),
})


const RolesEdit = () => {
    const [role, setRole] = useState<Role | undefined>()
    const [permissions, setPermissions] = useState<any>([]);
    const AccessApp = useAccessApp();
    const navigate = useNavigate()
    const params = useParams()
    const [formErrors, setFormErrors] = useState<string[]>([]);

    const initialValues = {
        name: role?.name || '',
        permissions : role?.permissions?.map((permission) => ({value: permission?.id, label: permission?.name,})) || [],
    }

    const cancel = () => {
        navigate('/roles')
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: editRoleSchema,
        onSubmit: async (values, {setSubmitting}) => {

            const permissionIds = values.permissions.map(permission => permission.value);
            setSubmitting(true)
            updateRole(params.id, {
                ...values,
                permissions: permissionIds
            }).then(response => {
                    if (axios.isAxiosError(response)) {
                        // we need to show the errors
                        setFormErrors(extractErrors(response));

                    } else if (response === undefined) {
                        // show generic error message
                        setFormErrors([GenericErrorMessage])
                    } else {
                        // we were able to store the user
                        AccessApp.setAlert({message: new AlertMessageGenerator('role', Actions.EDIT, AccessToastType.SUCCESS).message, type: AccessToastType.SUCCESS})
                        navigate(`/roles`);
                    }
                }
            );
        }
    })
    const handleChange = (selectedOptions:any) => {
        formik.setFieldValue('permissions', selectedOptions);
    };
    useEffect(() => {
        getRoleById(params.id).then(response => {
            setRole(response)
            AccessApp.setPageTitle(generatePageTitle(Sections.ROLES, PageTypes.EDIT , response?.name))
        })

        getAllPermissions().then(response => {
            setPermissions(response.data);

        })


    }, [params.id])

    return (
        <>
            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <span className="card-icon">
                            <i className="las la-plus fs-2"/>
                        </span>
                        <h3 className="card-label">
                            Edit Role
                        </h3>
                    </div>
                </div>
                <KTCardBody className='py-4'>
                    <FormErrors errorMessages={formErrors}/>
                    <form className='form' onSubmit={formik.handleSubmit} noValidate>
                        {/* begin::Scroll */}
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7 pt-5'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'
                        >
                            {/* begin::Input group */}
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Name</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {/*<Field name="name" type="text" className={'form-control form-control-solid mb-3 mb-lg-0' + (formik.touched.name && formik.errors.name ? ' is-invalid' : 'is-valid')} />*/}
                                <input
                                    placeholder='Name'
                                    {...formik.getFieldProps('name')}
                                    type='text'
                                    name='name'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.name && formik.errors.name},
                                        {
                                            'is-valid': formik.touched.name && !formik.errors.name,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Permissions</label>
                                {permissions && permissions.length > 0 &&
                                    <Select
                                        className='react-select-styled react-select-solid react-select-lg'
                                        closeMenuOnSelect={false}
                                        placeholder={'Select an option'}
                                        options={permissions.map((e: {
                                            name: any;
                                            id: any
                                        }) => ({label: e.name, value: e.id}))}
                                        isSearchable={true}
                                        onChange={handleChange}
                                        value={formik.values.permissions}
                                        isMulti
                                    />



                                }
                                {formik.touched.permissions && formik.errors.permissions && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.permissions}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Input group */}
                        </div>
                        {/* end::Scroll */}

                        {/* begin::Actions */}
                        <div className='py-5'>
                            <button
                                type='reset'
                                onClick={() => cancel()}
                                className='btn btn-light me-3'
                                data-kt-users-modal-action='cancel'
                                disabled={formik.isSubmitting}
                            >
                                Cancel
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Submit</span>
                                {(formik.isSubmitting) && (
                                    <span className='indicator-progress'>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}
                    </form>
                    {(formik.isSubmitting)}
                </KTCardBody>
            </KTCard>
        </>
    )
}

export {RolesEdit}