import React, {useEffect} from 'react'
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {KTCard, QUERIES} from "../../../../_metronic/helpers";
import {TableHeader} from "../../../modules/table/TableHeader";
import {QueryRequestProvider} from "../../../modules/table/QueryRequestProvider";
import {RolesTable} from "./RolesTable";
import {QueryResponseProvider} from "../../../modules/table/QueryResponseProvider";
import {getRoles} from "./core/_requests";
import {ListViewProvider} from "../../../modules/table/ListViewProvider";
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {PageTypes} from "../../../helpers/variables";

const RolesList = () => {
    const rolesBreadcrumbs: Array<PageLink> = [
        {
            title: Sections.ROLES,
            path: '/roles',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]
    const AccessApp = useAccessApp();
    useEffect(() => {
        AccessApp.setPageTitle(generatePageTitle(Sections.ROLES, PageTypes.INDEX))
    }, []);
    return (
        <>
            <PageTitle breadcrumbs={[]}>{'Roles'}</PageTitle>
            <KTCard>

                <TableHeader name='Role' url='/roles'  permission={'manage-roles'}/>
                <RolesTable/>
            </KTCard>
        </>
    )
}

const RolesIndex = () => (
    <QueryRequestProvider>
        <QueryResponseProvider id={QUERIES.ROLES_LIST} requestFunction={getRoles}>
            <ListViewProvider>
                <RolesList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {RolesIndex}
