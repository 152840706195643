import React, {useEffect, useState} from 'react'
import {useFormik} from "formik";
import * as Yup from 'yup'
import {isNotEmpty, KTCard, KTCardBody} from "../../../../_metronic/helpers";
import clsx from "clsx";
import {useNavigate} from 'react-router-dom';
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {AccessToastType, Actions, PageTypes} from "../../../helpers/variables";
import {createClient} from "./core/_requests";
import {getAllRoles} from "../../user/role/core/_requests";
import {getAllServices} from "../../services/services/core/_requests";
import Select from "react-select";
import Swal from "sweetalert2";
import {extractErrors, GenericErrorMessage} from "../../../helpers/form";
import {updateUser} from "../../user/user/core/_requests";
import axios from "axios";
import {AlertMessageGenerator} from "../../../helpers/AlertMessageGenerator";
import FormErrors from "../../../components/forms/FormErrors";

const createClientSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email : Yup.string().email('Invalid email format') .required('Email is required'),
    password: Yup.string().required('Password is Required'),
    website: Yup.string().required('Website is required'),
    company_name: Yup.string().required('Company Name is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    is_active: Yup.boolean(),
})

const ClientsCreate = () => {
    const navigate = useNavigate();
    const AccessApp = useAccessApp();
    const [isActive, setIsActive] = useState<boolean>(true)
    const [services, setServices] = useState<any>([])
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [clientForEdit] = useState<any>({
        name: '',
        email: '',
        website: '',
        company_name: '',
        password: '',
        phone_number: '',
        is_active: isActive,
        services: []
    })

    const cancel = () => {
        navigate('/clients')
    }

    const formik = useFormik({
        initialValues: clientForEdit,
        validationSchema: createClientSchema,
        onSubmit: async (values, {setSubmitting}) => {

            const servicesIds = values.services.map((service: { value: any; }) => service.value);
            setSubmitting(true)

            createClient({ ...values,
                services: servicesIds}).then(response => {
                    if (axios.isAxiosError(response)) {
                        // we need to show the errors
                        setFormErrors(extractErrors(response));

                    } else if (response === undefined) {
                        // show generic error message
                        setFormErrors([GenericErrorMessage])
                    } else {
                        // we were able to store the user
                        AccessApp.setAlert({message: new AlertMessageGenerator('client', Actions.CREATE, AccessToastType.SUCCESS).message, type: AccessToastType.SUCCESS})
                        navigate(`/clients`);
                    }
                }
            );

        },
    })
    const handleChange = (selectedOptions: any) => {
        formik.setFieldValue('services', selectedOptions);
    };
    useEffect(() => {
        AccessApp.setPageTitle(generatePageTitle(Sections.CLIENTS, PageTypes.CREATE))

        getAllServices().then((response) => {
            setServices(response.data);
        });
    }, []);

    return (
        <>
            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <span className="card-icon">
                            <i className="las la-plus fs-2" />
                        </span>
                        <h3 className="card-label">
                            Add Client
                        </h3>
                    </div>
                </div>
                <KTCardBody className='py-4'>
                    <FormErrors errorMessages={formErrors}/>
                    <form className='form' onSubmit={formik.handleSubmit} noValidate>
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7 pt-5'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Name</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Name'
                                    {...formik.getFieldProps('name')}
                                    type='text'
                                    name='name'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.name && formik.errors.name},
                                        {
                                            'is-valid': formik.touched.name && !formik.errors.name,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Email</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input placeholder='Email'{...formik.getFieldProps('email')} type='text' name='email' className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    {'is-invalid': formik.touched.email && formik.errors.email},
                                    {
                                        'is-valid': formik.touched.email && !formik.errors.email,
                                    }
                                )}
                                       autoComplete='off'
                                       disabled={formik.isSubmitting}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}



                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Password</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input placeholder='Password'{...formik.getFieldProps('password')} type='password' name='password' className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    {'is-invalid': formik.touched.password && formik.errors.password},
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                                       autoComplete='off'
                                       disabled={formik.isSubmitting}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}


                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Company Name</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Company Name'
                                    {...formik.getFieldProps('company_name')}
                                    type='text'
                                    name='company_name'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.company_name && formik.errors.company_name},
                                        {
                                            'is-valid': formik.touched.company_name && !formik.errors.company_name,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.company_name && formik.errors.company_name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.company_name}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Website</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Website'
                                    {...formik.getFieldProps('website')}
                                    type='text'
                                    name='website'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.website && formik.errors.website},
                                        {
                                            'is-valid': formik.touched.website && !formik.errors.website,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.website && formik.errors.website && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.website}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Phone Number</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Phone Number'
                                    {...formik.getFieldProps('phone_number')}
                                    type='text'
                                    name='phone_number'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.phone_number && formik.errors.phone_number},
                                        {
                                            'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.phone_number && formik.errors.phone_number && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.phone_number}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>


                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Services</label>
                                {services && services.length > 0 &&
                                    <Select
                                        className='react-select-styled react-select-solid react-select-lg'
                                        closeMenuOnSelect={false}
                                        placeholder={'Select an option'}
                                        options={services.map((e: {
                                            name: any;
                                            id: any
                                        }) => ({label: e.name, value: e.id}))}
                                        isSearchable={true}
                                        onChange={handleChange}
                                        value={formik.values.services}
                                        isMulti
                                    />
                                }
                                {formik.touched.services && formik.errors.services && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.services}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/*<div className='fv-row mb-7'>*/}
                            {/*    <input*/}
                            {/*        type="checkbox"*/}
                            {/*        checked={isActive}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            setIsActive(e.target.checked);*/}
                            {/*            formik.setFieldValue('is_active', e.target.checked);}}*/}
                            {/*    />*/}
                            {/*    <span className={'ms-3'}>Is Active </span>*/}
                            {/*</div>*/}

                        </div>
                        <div className='py-5'>
                            <button
                                type='reset'
                                onClick={() => cancel()}
                                className='btn btn-light me-3'
                                data-kt-users-modal-action='cancel'
                                disabled={formik.isSubmitting}
                            >
                                Cancel
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Submit</span>
                                {(formik.isSubmitting) && (
                                    <span className='indicator-progress'>
                Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                                )}
                            </button>
                        </div>

                    </form>
                    {(formik.isSubmitting)}
                </KTCardBody>
            </KTCard>
        </>
    )
}

export {ClientsCreate}