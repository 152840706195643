/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {ThemePicker} from "../../../../app/layout/partials/header-menus/ThemePicker";
import {toAbsoluteUrl} from "../../../helpers";

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()

  // console.log(currentUser?.meta.image);

  return (
      <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
          data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-2'>
            <div className='symbol symbol-25px me-3'>
              <img alt='Logo' src={toAbsoluteUrl("/media/avatars/blank.png")} />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.name}
                {/*<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>*/}
              </div>
              <span className='fw-bold text-muted fs-7'>
              {currentUser?.email}
            </span>
            </div>
          </div>
        </div>

        <div className='separator my-2'/>

        {/*<div className='menu-item px-5'>*/}
        {/*  <Link to={'/crafted/pages/profile'} className='menu-link px-5'>*/}
        {/*    My Profile*/}
        {/*  </Link>*/}
        {/*</div>*/}



        {/*<div className='separator my-2'/>*/}

        {/*<Languages/>*/}
        {/*<ThemePicker/>*/}

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>
  )
}

export {HeaderUserMenu}
