import React, {useEffect} from 'react'
import {PageTitle} from "../../../../_metronic/layout/core";
import {KTCard, QUERIES} from "../../../../_metronic/helpers";
import {TableHeader} from "../../../modules/table/TableHeader";
import {QueryRequestProvider} from "../../../modules/table/QueryRequestProvider";
import {UsersTable} from "./UsersTable";
import {QueryResponseProvider} from "../../../modules/table/QueryResponseProvider";
import {getUsers} from "./core/_requests";
import {ListViewProvider} from "../../../modules/table/ListViewProvider";
import {AccessApp, useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {PageTypes} from "../../../helpers/variables";
import {Sections} from "../../../helpers/sections";

const UsersList = () => {
    const AccessApp = useAccessApp();
    useEffect(() => {
        AccessApp.setPageTitle(generatePageTitle(Sections.USERS, PageTypes.INDEX))

    }, []);
    return (
        <>
            {/*<PageTitle breadcrumbs={[]}>{'Users'}</PageTitle>*/}
            <KTCard>

                <TableHeader name='User' url='/users' permission={'manage-users'}/>
                <UsersTable/>
            </KTCard>
        </>
    )
}

const UsersIndex = () => (
    <QueryRequestProvider>
        <QueryResponseProvider id={QUERIES.USERS_LIST} requestFunction={getUsers}>
            <ListViewProvider>
                <UsersList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {UsersIndex}
