import {Column} from 'react-table'
import {TextCell} from "../../../modules/table/columns/TextCell";
import {CustomHeader} from "../../../modules/table/columns/CustomHeader";
import {ActionsCell} from "../../../modules/table/columns/ActionsCell";
import {QUERIES} from "../../../../_metronic/helpers";
import {Client} from "../../../models/clients/Client";
import {Restricted, useAccessControl} from "../../../modules/auth/AuthAccessControl";

const clientColumns: ReadonlyArray<Column<Client>> = [
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Name' className='min-w-125px'/>,
    id: 'name',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].user.name}/>,
  },
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Email' className='min-w-125px'/>,
    id: 'email',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].user.email}/>,
  },
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Phone Number' className='min-w-125px'/>,
    id: 'phone_number',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].phone_number}/>,
  },
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Company Name' className='min-w-125px'/>,
    id: 'company_name',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].company_name}/>,
  },
  {
    Header: (props) =>
        <CustomHeader tableProps={props} title='Website' className='min-w-125px'/>,
    id: 'website',
    Cell: ({...props}) => <TextCell dObject={props.data[props.row.index].website}/>,
  },

  {
    Header: (props) => (
        <Restricted to={'manage-clients'}>
          <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px'/>
        </Restricted>
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const accessControl = useAccessControl();

      return (<ActionsCell
          id={props.data[props.row.index].id}
          path={'clients'}
          queryKey={QUERIES.CLIENT_LIST}
          showView={false}
          showEdit={accessControl.userCan('manage-clients')}
          showDelete={accessControl.userCan('manage-clients')}
          title="Delete Client"
          text={`Are you sure you want to delete the client '${props.data[props.row.index].user.name}'?`}
      />)
    },
  },
]

export {clientColumns}
