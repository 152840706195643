import axios from 'axios'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URL

export const deleteObject = async (link: string): Promise<void> => {

    return axios.delete(`${API_URL}/${link}`);
}


