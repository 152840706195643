// import {useListView} from '../../core/ListViewProvider'
// import {UsersListToolbar} from './UserListToolbar'
// import {UsersListGrouping} from './UsersListGrouping'
// import {UsersListSearchComponent} from './UsersListSearchComponent'

// import {TableSearchComponent} from "./TableSearchComponent";
// import {TableListGrouping} from "./TableListGrouping";
import {TableListToolbar} from "./TableListToolbar";
import {FC} from "react";
import {TableSearchComponent} from "./TableSearchComponent";
import {Restricted} from "../auth/AuthAccessControl";
// import {useListView} from "./ListViewProvider";

type Props = {
    name: string,
    url: string,
    permission ?: string
}


const TableHeader: FC<Props> = ({name, url, permission}) => {
    // const {selected} = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            <TableSearchComponent/>
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                <Restricted to={`${permission}`}>
                    <TableListToolbar name={name} url={url}/>
                </Restricted>
                {/*{selected.length > 0 ? <TableListGrouping/> : <TableListToolbar/>}*/}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}

export {TableHeader}
