import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createClient, getClientById, updateClient} from "./core/_requests";
import {KTCard, KTCardBody} from "../../../../_metronic/helpers";
import clsx from "clsx";
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {AccessToastType, Actions, PageTypes} from "../../../helpers/variables";
import {Client} from "../../../models/clients/Client";
import Select from "react-select";
import {getAllServices} from "../../services/services/core/_requests";
import axios from "axios";
import {extractErrors, GenericErrorMessage} from "../../../helpers/form";
import {AlertMessageGenerator} from "../../../helpers/AlertMessageGenerator";
import FormErrors from "../../../components/forms/FormErrors";

const editClientSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),

})


const ClientsEdit = () => {
    const [client, setClient] = useState<Client | undefined>()

    const AccessApp = useAccessApp();
    const navigate = useNavigate()
    const params = useParams()
    const [services, setServices] = useState<any>([])
    const [formErrors, setFormErrors] = useState<string[]>([]);

    const initialValues = {
        name: client?.user && client?.user.name || '',
        email: client?.user && client?.user.email || '',
        website: client?.website || '',
        company_name: client?.company_name || '',
        phone_number: client?.phone_number || '',
        is_active: client?.is_active,
        services:  client?.services?.map((service) => ({value: service?.id, label: service?.name,})) || [],
    }

    const cancel = () => {
        navigate('/clients')
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: editClientSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true)
            const servicesIds = values.services.map((service: { value: any; }) => service.value);

            updateClient(params.id,
                { ...values,
                services: servicesIds}).then(response => {

                    if (axios.isAxiosError(response)) {
                        // we need to show the errors
                        setFormErrors(extractErrors(response));
                        setSubmitting(false)
                    } else if (response === undefined) {
                        // show generic error message
                        setFormErrors([GenericErrorMessage])
                        setSubmitting(false)
                    } else {
                        // we were able to store the user
                        AccessApp.setAlert({message: new AlertMessageGenerator('client', Actions.EDIT, AccessToastType.SUCCESS).message, type: AccessToastType.SUCCESS})
                        navigate(`/clients`);
                    }
                }
            );
        }
    })

    const handleChange = (selectedOptions: any) => {
        formik.setFieldValue('services', selectedOptions);
    };

    useEffect(() => {
        getClientById(params.id).then(response => {
            setClient(response)
            AccessApp.setPageTitle(generatePageTitle(Sections.CLIENTS, PageTypes.EDIT , response?.user?.name))
        })

        getAllServices().then((response) => {
            setServices(response.data);
            console.log(services)
        });

    }, [params.id])

    return (
        <>
            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <span className="card-icon">
                            <i className="las la-plus fs-2"/>
                        </span>
                        <h3 className="card-label">
                            Edit Client
                        </h3>
                    </div>
                </div>
                <KTCardBody className='py-4'>
                    <FormErrors errorMessages={formErrors}/>
                    <form className='form' onSubmit={formik.handleSubmit} noValidate>
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7 pt-5'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Name</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Name'
                                    {...formik.getFieldProps('name')}
                                    type='text'
                                    name='name'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.name && formik.errors.name},
                                        {
                                            'is-valid': formik.touched.name && !formik.errors.name,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Email</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input placeholder='Email'{...formik.getFieldProps('email')} type='text' name='email' className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    {'is-invalid': formik.touched.email && formik.errors.email},
                                    {
                                        'is-valid': formik.touched.email && !formik.errors.email,
                                    }
                                )}
                                       autoComplete='off'
                                       disabled={true}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}



                            </div>


                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Company Name</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Company Name'
                                    {...formik.getFieldProps('company_name')}
                                    type='text'
                                    name='company_name'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.company_name && formik.errors.company_name},
                                        {
                                            'is-valid': formik.touched.company_name && !formik.errors.company_name,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.company_name && formik.errors.company_name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.company_name}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Website</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Website'
                                    {...formik.getFieldProps('website')}
                                    type='text'
                                    name='website'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.website && formik.errors.website},
                                        {
                                            'is-valid': formik.touched.website && !formik.errors.website,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.website && formik.errors.website && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.website}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Phone Number</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Phone Number'
                                    {...formik.getFieldProps('phone_number')}
                                    type='text'
                                    name='phone_number'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.phone_number && formik.errors.phone_number},
                                        {
                                            'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.phone_number && formik.errors.phone_number && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.phone_number}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>


                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Services</label>
                                {services && services.length > 0 &&
                                    <Select
                                        className='react-select-styled react-select-solid react-select-lg'
                                        closeMenuOnSelect={false}
                                        placeholder={'Select an option'}
                                        options={services.map((e: {
                                            name: any;
                                            id: any
                                        }) => ({label: e.name, value: e.id}))}
                                        isSearchable={true}
                                        onChange={handleChange}
                                        value={formik.values.services}
                                        isMulti
                                    />
                                }
                                {formik.touched.services && formik.errors.services && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.services}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/*<div className='fv-row mb-7'>*/}
                            {/*    <input*/}
                            {/*        type="checkbox"*/}
                            {/*        checked={formik.values.is_active}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            formik.setFieldValue('is_active', e.target.checked);}}*/}
                            {/*    />*/}
                            {/*    <span className={'ms-3'}>Is Active </span>*/}
                            {/*</div>*/}

                        </div>
                        <div className='py-5'>
                            <button
                                type='reset'
                                onClick={() => cancel()}
                                className='btn btn-light me-3'
                                data-kt-users-modal-action='cancel'
                                disabled={formik.isSubmitting}
                            >
                                Cancel
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Submit</span>
                                {(formik.isSubmitting) && (
                                    <span className='indicator-progress'>
                Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                                )}
                            </button>
                        </div>

                    </form>
                    {(formik.isSubmitting)}
                </KTCardBody>
            </KTCard>
        </>
    )
}

export {ClientsEdit}