const QUERIES = {
  ACTIVITIES_LIST: 'activities-list',

  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  PERMISSIONS_LIST: 'permissions-list',
  SERVICE_LIST: 'service-list',
  CLIENT_LIST: 'client-list',
  CLIENT_SERVICE_LIST: 'client-service-list',
  SERVICE_FORM_LIST :'service-form-list'
}

export {QUERIES}
