import React, {useEffect} from 'react'
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {KTCard, QUERIES} from "../../../../_metronic/helpers";
import {TableHeader} from "../../../modules/table/TableHeader";
import {QueryRequestProvider} from "../../../modules/table/QueryRequestProvider";
import {QueryResponseProvider} from "../../../modules/table/QueryResponseProvider";
import {getClients} from "./core/_requests";
import {ListViewProvider} from "../../../modules/table/ListViewProvider";
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {PageTypes} from "../../../helpers/variables";
import {ClientsTable} from "./ClientsTable";

const ClientList = () => {

    const AccessApp = useAccessApp();
    useEffect(() => {
        AccessApp.setPageTitle(generatePageTitle(Sections.CLIENTS, PageTypes.INDEX))
    }, []);
    return (
        <>
            <PageTitle breadcrumbs={[]}>{'Clients'}</PageTitle>
            <KTCard>
                <TableHeader name='Client' url='/clients'  permission={'manage-clients'}/>
                <ClientsTable/>
            </KTCard>
        </>
    )
}

const ClientsIndex = () => (
    <QueryRequestProvider>
        <QueryResponseProvider id={QUERIES.CLIENT_LIST} requestFunction={getClients}>
            <ListViewProvider>
                <ClientList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {ClientsIndex}
