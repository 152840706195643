import React, {useEffect} from 'react'
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {KTCard, QUERIES} from "../../../../_metronic/helpers";
import {TableHeader} from "../../../modules/table/TableHeader";
import {QueryRequestProvider} from "../../../modules/table/QueryRequestProvider";
import {QueryResponseProvider} from "../../../modules/table/QueryResponseProvider";
import {getServices} from "./core/_requests";
import {ListViewProvider} from "../../../modules/table/ListViewProvider";
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {PageTypes} from "../../../helpers/variables";
import {ServicesTable} from "./ServicesTable";

const ServicesList = () => {

    const AccessApp = useAccessApp();
    useEffect(() => {
        AccessApp.setPageTitle(generatePageTitle(Sections.SERVICES, PageTypes.INDEX))
    }, []);
    return (
        <>
            <PageTitle breadcrumbs={[]}>{'Services'}</PageTitle>
            <KTCard>
                <TableHeader name='Service' url='/services'  permission={'manage-services'}/>
                <ServicesTable/>
            </KTCard>
        </>
    )
}

const ServicesIndex = () => (
    <QueryRequestProvider>
        <QueryResponseProvider id={QUERIES.SERVICE_LIST} requestFunction={getServices}>
            <ListViewProvider>
                <ServicesList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {ServicesIndex}
