import axios, {AxiosError, AxiosResponse} from 'axios'
import {ID, Response} from "../../../../../_metronic/helpers";
import {User} from "../../../../models/user/User";
import {UserQueryResponse} from "../../../../models/user/User";
import {Role} from "../../../../models/user/Role";


const API_URL = process.env.REACT_APP_API_URL
const GET_USERS_URL = `${API_URL}/users`

const getUserById = (id: any): Promise<User | undefined> => {
    return axios
        .get(`${GET_USERS_URL}/${id}`)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const getUsers = (query: string): Promise<UserQueryResponse> => {
    return axios
        .get(`${GET_USERS_URL}/all?${query}`)
        .then((d: AxiosResponse<UserQueryResponse>) => d.data)
}
const createUser = (user: User): Promise<User | undefined> => {
    return axios
        .post(GET_USERS_URL, user)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}
export const storeUser = async (user: any): Promise<User | AxiosError | undefined> => {

    return await axios.post(GET_USERS_URL , user).then(res => res.data.data).catch((error) => {
        return error;
    });
}
const updateUser = async (id:any , values: any): Promise<User | AxiosError | undefined> => {
    return await axios .put(`${GET_USERS_URL}/${id}`, values).then(res => res.data.data).catch((error) => {
        return error;
    });
}
const updateUserPost = (id:any , values: any): Promise<User | undefined> => {
    return axios
        .post(`${GET_USERS_URL}/${id}/update`, values)
        .then((response) => response.data)
        // .then((response: Response<User>) => response.data)
}
const deleteUser = (userId: ID): Promise<void> => {
    return axios.delete(`${GET_USERS_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
    const requests = userIds.map((id) => axios.delete(`${GET_USERS_URL}/${id}`))
    return axios.all(requests).then(() => {})
}

export {getUserById, getUsers, createUser, updateUser, deleteUser,updateUserPost}
