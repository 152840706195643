export enum AccessToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    PENDING = 'pending',
}

export enum PageTypes {
    INDEX = 'index',
    CREATE = 'create',
    EDIT = 'edit',
    SHOW = 'show',
    REPORT = 'report',
    ERROR = 'error',
    ARCHIVED = 'archived',
}
export enum Actions {
    CREATE = 1,
    EDIT,
    FILTER,
    EXPORT,
    SELECT
}

export enum forms{
    WebsiteMaintenance = 1 ,
    SocialMedia,
    Publication,
    NFT,
    GoogleAds,
    FBAds,
    EcommerceWebsite,
    CorporateWebsite
}