import axios, {AxiosError, AxiosResponse} from 'axios'
import {ID, Response} from "../../../../../_metronic/helpers";
import {Service, ServiceQueryResponse} from "../../../../models/services/Service";
import {Client, ClientQueryResponse} from "../../../../models/clients/Client";
import {User} from "../../../../models/user/User";

const API_URL = process.env.REACT_APP_API_URL
const GET_CLIENTS_URL = `${API_URL}/clients`

const getClients = (query: string): Promise<ClientQueryResponse> => {
    return axios
        .get(`${GET_CLIENTS_URL}/all?${query}`)
        .then((d: AxiosResponse<ClientQueryResponse>) => d.data)
}


const getAllClients = (): Promise<any> => {
    return axios
        .get(`${GET_CLIENTS_URL}` )
        .then((response) => response.data)
}
const getClientById = (id: any): Promise<Client | undefined> => {
    return axios
        .get(`${GET_CLIENTS_URL}/${id}`)
        .then((response: AxiosResponse<Response<Client>>) => response.data)
        .then((response: Response<Client>) => response.data)
}

const createClient = async (client: any): Promise<Client | AxiosError | undefined> => {
    return await axios.post(GET_CLIENTS_URL , client).then(res => res.data.data).catch((error) => {
        return error;
    });
}

const updateClient = async (id: any, values: any): Promise<Client | AxiosError | undefined> => {
    return await axios.put(`${GET_CLIENTS_URL}/${id}` , values).then(res => res.data.data).catch((error) => {
        return error;
    });

}
const deleteClient = (id: ID): Promise<void> => {
    return axios.delete(`${GET_CLIENTS_URL}/${id}`).then(() => {})
}


export {getAllClients, getClients, getClientById, deleteClient , createClient, updateClient}
