import React, {useEffect} from 'react'
import {PageTitle} from "../../../../_metronic/layout/core";
import {KTCard, QUERIES} from "../../../../_metronic/helpers";
import {TableHeader} from "../../../modules/table/TableHeader";
import {QueryRequestProvider} from "../../../modules/table/QueryRequestProvider";
import {PermissionsTable} from "./PermissionsTable";
import {getPermissions} from "./core/_requests";
import {QueryResponseProvider} from "../../../modules/table/QueryResponseProvider";
import {ListViewProvider} from "../../../modules/table/ListViewProvider";
import {useAccessApp} from "../../../modules/general/AcessApp";
import {generatePageTitle} from "../../../helpers/pageTitleGenerator";
import {Sections} from "../../../helpers/sections";
import {PageTypes} from "../../../helpers/variables";

const PermissionsList = () => {
    const AccessApp = useAccessApp();
    useEffect(() => {
        AccessApp.setPageTitle(generatePageTitle(Sections.PERMISSIONS, PageTypes.INDEX))
    }, []);
    return (
        <>
            <KTCard>
                <TableHeader name='Permission' url="/permissions"  permission={'manage-permissions'}/>
                <PermissionsTable/>
            </KTCard>
        </>
    )
}

const PermissionsIndex = () => (
    <QueryRequestProvider>
        <QueryResponseProvider id={QUERIES.PERMISSIONS_LIST} requestFunction={getPermissions}>
            <ListViewProvider>
                <PermissionsList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {PermissionsIndex}
