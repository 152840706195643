/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {Restricted} from "../../../../app/modules/auth/AuthAccessControl";

export function AsideMenuMain() {
    const intl = useIntl()

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />
            {/*<div className='menu-item'>*/}
            {/*    <div className='menu-content pt-8 pb-2'>*/}
            {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sections</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<AsideMenuItem*/}
            {/*    to='/activities'*/}
            {/*    title='activities'*/}
            {/*    fontIcon='bi-archive'*/}
            {/*    icon='/media/icons/duotune/general/gen022.svg'*/}
            {/*>*/}
            {/*</AsideMenuItem>*/}

            {/*<AsideMenuItem*/}
            {/*    to='/games'*/}
            {/*    title='games'*/}
            {/*    fontIcon='bi-archive'*/}
            {/*    icon='/media/icons/duotune/general/gen022.svg'*/}
            {/*>*/}
            {/*</AsideMenuItem>*/}
            <Restricted to='view-users'>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sections</span>
                    </div>
                </div>
            </Restricted>
            <Restricted to='view-users'>
                <AsideMenuItem
                    to='/users'
                    title='Users'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen049.svg'
                >
                </AsideMenuItem>
            </Restricted>
            <Restricted to='view-roles'>
                <AsideMenuItem
                    to='/roles'
                    title='Roles'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen009.svg'
                >
                </AsideMenuItem>
            </Restricted>
            <Restricted to='view-permissions'>
                <AsideMenuItem
                    to='/permissions'
                    title='Permissions'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen019.svg'
                >
                </AsideMenuItem>
            </Restricted>
            {/*<Restricted to='view-services'>*/}
            {/*    <div className='menu-item'>*/}
            {/*        <div className='menu-content pt-8 pb-2'>*/}
            {/*            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Clients</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Restricted>*/}
            <Restricted to='view-services'>
                <AsideMenuItem
                    to='/services'
                    title='Services'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen015.svg'
                >
                </AsideMenuItem>
            </Restricted>
            <Restricted to='view-clients'>
                <AsideMenuItem
                    to='/clients'
                    title='Clients'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen024.svg'
                >
                </AsideMenuItem>
            </Restricted>
            <Restricted to='view-own-services'>
                <AsideMenuItem
                    to='/client/services'
                    title='Services'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen015.svg'
                >
                </AsideMenuItem>
            </Restricted>

            {/*<AsideMenuItemWithSub*/}
            {/*    to=''*/}
            {/*    title='roles'*/}
            {/*    fontIcon='bi-archive'*/}
            {/*    icon='/media/icons/duotune/general/gen022.svg'*/}
            {/*>*/}
            {/*    <AsideMenuItem to='/roles/' title='List Roles'/>*/}
            {/*    <AsideMenuItem to='/roles/create' title='Create Role'/>*/}
            {/*</AsideMenuItemWithSub>*/}
        </>
    )
}
