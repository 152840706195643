import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Sections} from '../../helpers/sections';
import {SuspenseView} from "../../components/misc/SuspenseView";
import {RolesIndex} from "../../sections/user/role/RolesIndex";
import {RolesCreate} from "../../sections/user/role/RolesCreate";
import {RolesEdit} from "../../sections/user/role/RolesEdit";

const rolesBreadcrumbs: Array<PageLink> = [
    {
        title: Sections.ROLES,
        path: '/roles',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const RoleRoutes: React.FC = () => {
    return (
        <Routes>
            <Route index element={
                <SuspenseView>
                    <PageTitle breadcrumbs={[]}>{'Roles'}</PageTitle>
                    <RolesIndex/>
                </SuspenseView>
            }/>
            <Route
                path='/create'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={rolesBreadcrumbs} showPageTitle={true}>{'Create'}</PageTitle>
                        <RolesCreate/>
                    </SuspenseView>
                }
            />
            <Route
                path='/:id/edit'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={rolesBreadcrumbs} showPageTitle={false}>{'Edit'}</PageTitle>
                        <RolesEdit/>
                    </SuspenseView>
                }
            />
        </Routes>
    )
}

export default RoleRoutes;
