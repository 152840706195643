import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import RoleRoutes from "./Users/RoleRoutes";
import PermissionRoutes from "./Users/PermissionRoutes";
import UserRoutes from "./Users/UserRoutes";
import ServicesRoutes from "./Services/ServicesRoutes";
import ClientsRoutes from "./Client/ClientsRoutes";
import ClientOwnRoutes from "./Client/ClientOwnRoutes";
import {ServicesFormIndex} from "../sections/clients/clientServices/forms/ServiceFormIndex";
import {ServiceFormEdit} from "../sections/clients/clientServices/forms/ServiceFormEdit";
import ClientsFormRoutes from "./Client/ClientsFormRoutes";


const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper/>}/>
                <Route path='menu-test' element={<MenuTestPage/>}/>
                {/* Pages */}
                <Route path='roles/*' element={<RoleRoutes/>}/>
                <Route path='permissions/*' element={<PermissionRoutes/>}/>
                <Route path='users/*' element={<UserRoutes/>}/>
                <Route path='services/*' element={<ServicesRoutes/>}/>
                <Route path='client/services/:service/*' element={<ClientsFormRoutes/>}/>
                <Route path='client/services/*' element={<ClientOwnRoutes/>}/>
                <Route path='clients/*' element={<ClientsRoutes/>}/>



                {/*<Route path='client/service/forms/:id' element={<ServicesFormIndex/>}/>*/}

                {/*<Route path='client/service/:service/forms/:id' element={<ServiceFormEdit/>}/>*/}
                {/*<Route path='users' element={<UsersIndex/>}/>*/}
                {/*<Route*/}
                {/*    path='users/create'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <UsersCreate/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path='users/:id/edit'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <UsersEdit/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}

                {/*<Route path='roles' element={<RolesIndex/>}/>*/}
                {/*<Route*/}
                {/*    path='roles/create'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <RolesCreate/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path='roles/:id/edit'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <RolesEdit/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}

                {/*<Route path='permissions' element={<PermissionsIndex/>}/>*/}
                {/*<Route*/}
                {/*    path='permissions/create'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <PermissionsCreate/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path='permissions/:id/edit'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <PermissionsEdit/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}

                {/*/!* Lazy Modules *!/*/}
                {/*<Route*/}
                {/*    path='crafted/pages/profile/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <ProfilePage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/pages/wizards/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <WizardsPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/widgets/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <WidgetsPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/account/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <AccountPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='apps/chat/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <ChatPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
