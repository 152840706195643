import {FC, useEffect, useState} from 'react'
import {ID, stringifyRequestQuery} from "../../../../_metronic/helpers";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {Link} from "react-router-dom";
import {useQueryClient} from "react-query";
import {deleteObject} from "../../../requests";
import {useQueryRequest} from "../QueryRequestProvider";
import {useAuth} from "../../auth";
import Swal from "sweetalert2";
import {extractErrors} from "../../../helpers/form";
import axios from 'axios';

type Props = {
    id: any,
    path: string,
    queryKey: string,
    showEdit?: boolean
    showDelete?: boolean
    showView?: boolean
    title?: any
    text?: any
    callBackFn?: any,
    id2?: any,
    path2?: string
    showView2?: boolean
    indexView?:boolean
}

const ActionsCell: FC<Props> = ({
                                    id,
                                    path,
                                    queryKey,
                                    showEdit,
                                    showDelete = true,
                                    showView,
                                    callBackFn,
                                    title,
                                    text,
                                    id2,
                                    path2,
                                    showView2,
                                    indexView
                                }) => {


    const queryClient = useQueryClient();
    const {state} = useQueryRequest();
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
    const {auth, saveAuth} = useAuth();

    useEffect(() => {
        MenuComponent.reinitialization()
    }, []);

    useEffect(() => {
        setQuery(stringifyRequestQuery(state));
    }, [state]);
    const deleteItem = async () => {
        const {isConfirmed} = await Swal.fire({
            title: title ? title : 'Delete',
            text: text ? text : 'Are you sure you want to delete this item?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm Delete',
            confirmButtonColor: "#DB4437",
            cancelButtonText: 'Dismiss',
            reverseButtons: true
        })

        if (isConfirmed) {
            deleteObject(path + '/' + id)
                .then(() => {
                    queryClient.invalidateQueries(`${queryKey}-${query}`)
                }).catch((error) => {
                if (axios.isAxiosError(error)) {
                    const errorMessages = extractErrors(error).map((errorMessage) => `<li>${errorMessage}</li>`)

                    // we need to show the error
                    Swal.fire(
                        'Something Wrong Happened',
                        "<p>" + errorMessages.join() + "</p>",
                        "error"
                    );
                } else if (error === undefined) {
                    // we need to show a generic error
                    Swal.fire(
                        'Something Wrong Happened',
                        "<p>Could not complete your request. Please try again later.</p>",
                        "error"
                    );
                }
            }).finally(() => {
                if (callBackFn) {
                    callBackFn()
                }
            });
        }
    }
    return (
        <>
            {showView && (
                <Link to={'/' + path + '/' + id} className='btn btn-icon btn-active-light-info'>
                    <i className="fa-solid fa-eye text-primary"/>
                </Link>
            )}
            {showView2 && (
                <Link to={'/' + path + '/' + id + '/' + path2 + '/' + id2} className='btn btn-icon btn-active-light-info'>
                    <i className="fa-solid fa-eye text-primary"/>
                </Link>
            )}
            {indexView && (
                <Link to={'/' + path } className='btn btn-icon btn-active-light-info'>
                    <i className="fa-solid fa-eye text-primary"/>
                </Link>
            )}
            {showEdit && (
                <Link
                    to={'/' + path + '/' + id + '/edit'}
                    className='btn btn-icon btn-sm btn-active-light-warning'
                >
                    <i className="fa-solid fa-pencil text-warning"/>
                </Link>
            )}

            {showDelete && (
                <a
                    className='btn btn-icon btn-sm btn-active-light-danger'
                    onClick={async () => deleteItem()}
                >
                    <i className="fa-solid fa-trash text-danger"/>
                </a>
            )}
        </>
    )
}

export {ActionsCell}
