import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {SuspenseView} from '../../components/misc/SuspenseView'

import {Sections} from '../../helpers/sections';
import {PermissionsEdit} from "../../sections/user/permission/PermissionsEdit";
import {PermissionsCreate} from "../../sections/user/permission/PermissionsCreate";
import {PermissionsIndex} from "../../sections/user/permission/PermissionsIndex";

const permissionsBreadcrumbs: Array<PageLink> = [
    {
        title: Sections.PERMISSIONS,
        path: '/permissions/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const PermissionRoutes: React.FC = () => {
    return (
        <Routes>
            <Route index element={
                <SuspenseView>
                    <PageTitle breadcrumbs={[]}>{'Permissions'}</PageTitle>
                    <PermissionsIndex/>
                </SuspenseView>
            }/>

            <Route
                path='/create'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={permissionsBreadcrumbs} showPageTitle={false}>{'Create'}</PageTitle>
                        <PermissionsCreate/>
                    </SuspenseView>
                }
            />
            <Route
                path='/:id/edit'
                element={
                    <SuspenseView>
                        <PageTitle breadcrumbs={permissionsBreadcrumbs} showPageTitle={false}>{'Edit'}</PageTitle>
                        <PermissionsEdit/>
                    </SuspenseView>
                }
            />
        </Routes>
    )
}

export default PermissionRoutes;
