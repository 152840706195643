import axios, {AxiosError, AxiosResponse} from 'axios'
import {ID, Response} from "../../../../../_metronic/helpers";
import {Service, ServiceQueryResponse} from "../../../../models/services/Service";
import {Client} from "../../../../models/clients/Client";

const API_URL = process.env.REACT_APP_API_URL
const GET_SERVICES_URL = `${API_URL}/client/service`

const getClientServices = (query: string): Promise<ServiceQueryResponse> => {
    return axios
        .get(`${GET_SERVICES_URL}/all?${query}`)
        .then((d: AxiosResponse<ServiceQueryResponse>) => d.data)
}


const getAllClientServices = (): Promise<any> => {
    return axios
        .get(`${GET_SERVICES_URL}` )
        .then((response) => response.data)
}
const getClientServiceById = (id: any): Promise<Service | undefined> => {
    return axios
        .get(`${GET_SERVICES_URL}/${id}`)
        .then((response: AxiosResponse<Response<Service>>) => response.data)
        .then((response: Response<Service>) => response.data)
}

const createClientService = async (service: Service): Promise<Service | AxiosError | undefined> => {
    return await axios
        .post(`${GET_SERVICES_URL}`, service)
        .then(res => res.data.data).catch((error) => {
            return error;
        });
}

const updateClientService = async (id: any, values: any): Promise<Service | AxiosError | undefined> => {
    return await axios
        .put(`${GET_SERVICES_URL}/${id}`, values)
        .then(res => res.data.data).catch((error) => {
            return error;
        });
}
const deleteClientService = (id: ID): Promise<void> => {
    return axios.delete(`${GET_SERVICES_URL}/${id}`).then(() => {})
}


export {getClientServices, getAllClientServices, getClientServiceById, createClientService,updateClientService,deleteClientService}
