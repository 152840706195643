export class RoutePermissionMapper {
    regexMap: Map<RegExp, string>;

    constructor() {
        this.regexMap = new Map<RegExp, string>();

        this.fillMap();
    }

    fillMap() {
        // Users

        this.regexMap.set(/^\/users\/?$/, 'view-users');
        this.regexMap.set(/^\/users\/\d+\/edit\/?$/, 'manage-users');
        this.regexMap.set(/^\/users\/[a-z-]+\/?$/, 'view-users');
        this.regexMap.set(/^\/users\/[a-z-]+\/create\/?$/, 'manage-users');
        this.regexMap.set(/^\/users\/[a-z-]+\/\d+\/edit\/?$/, 'manage-users');

        // Permissions
        this.regexMap.set(/^\/permissions\/?$/, 'view-permissions');
        this.regexMap.set(/^\/permissions\/\d+\/edit\/?$/, 'manage-permissions');
        this.regexMap.set(/^\/permissions\/[a-z-]+\/?$/, 'view-permissions');
        this.regexMap.set(/^\/permissions\/[a-z-]+\/create\/?$/, 'manage-permissions');
        this.regexMap.set(/^\/permissions\/[a-z-]+\/\d+\/edit\/?$/, 'manage-permissions');

        // Roles
        this.regexMap.set(/^\/roles\/?$/, 'view-roles');
        this.regexMap.set(/^\/roles\/\d+\/edit\/?$/, 'manage-roles');
        this.regexMap.set(/^\/roles\/[a-z-]+\/?$/, 'view-roles');
        this.regexMap.set(/^\/roles\/[a-z-]+\/create\/?$/, 'manage-roles');
        this.regexMap.set(/^\/roles\/[a-z-]+\/\d+\/edit\/?$/, 'manage-roles');

        // Services
        this.regexMap.set(/^\/services\/?$/, 'view-services');
        this.regexMap.set(/^\/services\/\d+\/edit\/?$/, 'manage-services');
        this.regexMap.set(/^\/services\/[a-z-]+\/?$/, 'view-services');
        this.regexMap.set(/^\/services\/[a-z-]+\/create\/?$/, 'manage-services');
        this.regexMap.set(/^\/services\/[a-z-]+\/\d+\/edit\/?$/, 'manage-services');


        // Clients
        this.regexMap.set(/^\/clients\/?$/, 'view-clients');
        this.regexMap.set(/^\/clients\/\d+\/edit\/?$/, 'manage-clients');
        this.regexMap.set(/^\/clients\/[a-z-]+\/?$/, 'view-clients');
        this.regexMap.set(/^\/clients\/[a-z-]+\/create\/?$/, 'manage-clients');
        this.regexMap.set(/^\/clients\/[a-z-]+\/\d+\/edit\/?$/, 'manage-clients');
    }

}