import axios, {AxiosError, AxiosResponse} from 'axios'

import {Response} from "../../../../../_metronic/helpers";
import {Permission, PermissionList, PermissionQueryResponse} from "../../../../models/user/Permission";
import {User} from "../../../../models/user/User";

const API_URL = process.env.REACT_APP_API_URL
const GET_PERMISSIONS_URL = `${API_URL}/permissions`

const getAllPermissions= (): Promise<any> => {
    return axios.get(GET_PERMISSIONS_URL)
        .then((response) => response.data)
}

const getPermissions = (query: string): Promise<PermissionQueryResponse> => {
    return axios
        .get(`${GET_PERMISSIONS_URL}/all?${query}`)
        .then((d: AxiosResponse<PermissionQueryResponse>) => d.data)
}

const getPermissionById = (id: any): Promise<Permission | undefined> => {
    return axios
        .get(`${GET_PERMISSIONS_URL}/${id}`)
        .then((response: AxiosResponse<Response<Permission>>) => response.data)
        .then((response: Response<Permission>) => response.data)
}

const createPermission = async (permission: Permission): Promise<Permission | AxiosError | undefined> => {
    return axios
        .post(`${GET_PERMISSIONS_URL}`, permission)
        .then(res => res.data.data).catch((error) => {
            return error;
        });
}

const updatePermission = (id: any, permission: Permission): Promise<Permission | AxiosError | undefined> => {
    return axios
        .put(`${GET_PERMISSIONS_URL}/${id}`, permission)
        .then(res => res.data.data).catch((error) => {
            return error;
        });
}

export {getPermissions, getPermissionById, createPermission, updatePermission,getAllPermissions}
